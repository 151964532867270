// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maplibregl-popup-close-button {
  font-size: 20px;
  margin-top: 4px;
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/utils/popUp.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".maplibregl-popup-close-button {\n  font-size: 20px;\n  margin-top: 4px;\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
