// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/geogtq-rg-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Geogrotesque-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
    font-weight: normal;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,2DAAyD;IACzD,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Geogrotesque-Regular';\n    src: url('./fonts/geogtq-rg-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
